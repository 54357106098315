* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
}
a {
  text-decoration: none;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.infinite-scroll-component__outerdiv{
  width: 100%
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}